export default defineI18nConfig(() => ({
  pluralRules: {
    ru: (choice, choicesLength) => {
      const delta = choicesLength === 4 ? 1 : 0;
      if (delta && !choice) return delta;

      const pluralRules = new Intl.PluralRules('ru-RU');
      switch (pluralRules.select(choice)) {
        case 'one':
          return delta;
        case 'few':
          return 1 + delta;
        case 'many':
          return 2 + delta;
      }

      return 0;
    },
  },
}));
